import $axios from './api';

export const create = async ({ courseId, module }) => {
	const { data } = await $axios.post(`/v1/author/course/${courseId}/module/create`, module);
	return data;
};

export const remove = async ({ courseId, moduleId }) => {
	const { data } = await $axios.delete(`/v1/author/course/${courseId}/module/${moduleId}`);
	return data;
};

export const edit = async ({ courseId, module }) => {
	const { data } = await $axios.post(`/v1/author/course/${courseId}/module/${module.id}`, module);
	return data;
};

export const removeAttachment = async ({ courseId, moduleId, attachmentId }) => {
	const { data } = await $axios.delete(`/v1/author/course/${courseId}/module/${moduleId}/attach/${attachmentId}`);
	return data;
};