import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: []
};

const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		setTagsList: (state, action) => {
			state.list = action.payload;
		},
		addTagsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeTagsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
	}
});

export const { setTagsList, addTagsItem, removeTagsItem } = tagsSlice.actions;

export const fetchTagsList = () => async (dispatch) => {

	try {

		const res = await api.tagsList();
		dispatch(setTagsList(res.list));

	} catch (error) {

		return Promise.reject(error);
		
	}

};

export const createTag = (tag) => async (dispatch) => {

	try {

		const res = await api.createTag(tag);

		if (res) {

			dispatch(addTagsItem(tag));

		} else {

			throw Error();

		}

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось создать тег',
		}));

		return Promise.reject(error);
		
	}

};

export default tagsSlice.reducer;