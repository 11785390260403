import $axios from './api';

export const login = async ({ email, password }) => {
	const { data } = await $axios.post('/v1/auth/login', { email, password });
	return data;
};

export const logout = async () => {
	const { data } = await $axios.post('/v1/auth/logout', {});
	return data;
};

export const getUser = async () => {
	const { data } = await $axios.get('/v1/author/profile');
	return data;
};

export const checkEmail = async (sendData) => {
	const { data } = await $axios.post('/v1/auth/checkEmail', sendData);
	return data;
};

export const checkRegistrationCode = async ({ email, code }) => {
	const { data } = await $axios.post('/v1/auth/checkRegistrationCode', { email, code });
	return data;
};

export const register = async (registerData) => {
	const { data } = await $axios.post('/v1/author/register', registerData);
	return data;
};

export const update = async (userData) => {
	const { data } = await $axios.post('/v1/author/profile', userData);
	return data;
};

export const forgotPassword = async (email) => {
	const { data } = await $axios.post('/v1/author/forgotPassword', { email });
	return data;
};

export const resetPassword = async ( sendData ) => {
	const { data } = await $axios.post('/v1/author/resetPassword', sendData );
	return data;
};