import $axios from './api';

export const create = async (course) => {

	const { data } = await $axios.post('/v1/author/course/create', course);
	return data;
};

export const list = async () => {

	// const { data } = await $axios.post('/v1/author/course/list', { limit, page, sortBy, sortDirection });
	const { data } = await $axios.post('/v1/author/course/list', {});
	return data;

};

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/author/course/${id}`);
	return data;

};