import $axios from './api';

export const create = async ( lecture ) => {
	const { data } = await $axios.post('/v1/author/lecture/create', lecture);
	return data;
};

export const list = async () => {

	// const { data } = await $axios.post('/v1/author/lecture/list', { limit, page, sortBy, sortDirection });
	const { data } = await $axios.post('/v1/author/lecture/list', { });
	return data;

};

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/author/lecture/${id}`);
	return data;

};

export const getImage = async (id) => {

	const { data } = await $axios.get(`/v1/author/lecture/${id}/image/link`);
	return data;

};