import $axios from './api';

export const list = async () => {

	const { data } = await $axios.post('/v1/author/tag/list', {});
	return data;

};

export const create = async ({ name }) => {

	const { data } = await $axios.post('/v1/author/tag/create', { name });
	return data;

};