import * as auth from './auth';
import * as upload from './upload';
import * as publications from './publications';
import * as lectures from './lectures';
import * as courses from './courses';
import * as stream from './stream';
import * as tags from './tags';
import * as country from './country';
import * as modules from './modules';
import * as referrals from './referrals';
import * as webinars from './webinars';

const api = {
	// auth
	login: auth.login,
	logout: auth.logout,
	getUser: auth.getUser,
	updateUserData: auth.update,
	checkEmail: auth.checkEmail,
	checkRegistrationCode: auth.checkRegistrationCode,
	register: auth.register,
	forgotPassword: auth.forgotPassword,
	resetPassword: auth.resetPassword,

	// upload
	createSession: upload.createSession,
	uploadSessionFile: upload.uploadSessionFile,
	closeSession: upload.closeSession,
	getSessionById: upload.getSessionById,
	uploadImage: upload.uploadImage,
	uploadRegisterImage: upload.uploadRegisterImage,
	uploadAttachmentModule: upload.uploadAttachmentModule,

	//publications
	publicationsList: publications.list,
	createPublication: publications.create,
	publicationById: publications.getById,
	editPublication: publications.edit,

	// lecture
	createLecture: lectures.create,
	lecturesList: lectures.list,
	lectureById: lectures.getById,
	lectureImage: lectures.getImage,

	// courses
	createCourse: courses.create,
	coursesList: courses.list,
	courseById: courses.getById,

	// modules
	createCourseModule: modules.create,
	removeCourseModule: modules.remove,
	editCourseModule: modules.edit,
	removeAttachmentModule: modules.removeAttachment,

	// streams
	getStreamById: stream.getStreamById,
	getStreamLinkById: stream.getStreamLinkById,
	getStreamList: stream.getStreamList,

	// tags
	tagsList: tags.list,
	createTag: tags.create,

	// countries
	countryList: country.list,

	//referrals
	referralsList: referrals.list,

	// webinars
	webinarById: webinars.getById,
	webinarsList: webinars.list,
	createWebinar: webinars.create,
	editWebinar: webinars.edit,
	cancelWebinar: webinars.cancel,
	editWebinarPublication: webinars.editPublication
};

export default api;
