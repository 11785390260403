import $axios from './api';

export const list = async (sendData = {}) => {

	const { data } = await $axios.post('/v1/author/publication/list', sendData);
	return data;

};

export const create = async ({ price, publicableID, publicableType }) => {

	const { data } = await $axios.post('/v1/author/publication/create', { price, publicableID, publicableType });
	return data;

};

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/author/publication/${id}`);
	return data;

};

export const edit = async ({ publicationId, sendData }) => {

	const { data } = await $axios.post(`/v1/author/publication/${publicationId}`, sendData);
	return data;

};