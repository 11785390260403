import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const referralsSlice = createSlice({

	name: 'referrals',
	initialState,
	reducers: {
		setReferralsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		updateReferralItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		addReferralItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeRefferalItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setReferralLoading: (state, action) => {
			state.loading = action.payload;
		}
	}

});

export const { setReferralsList, updateReferralItem, 
	addReferralItem, removeRefferalItem, 
	setReferralLoading } = referralsSlice.actions;


export const fetchReferralsList = (sendData = {}) => async (dispatch) => {
	try {

		dispatch(setReferralLoading(true));

		const res = await api.referralsList(sendData);
		dispatch(setReferralsList(res));

	} catch (error) {

		dispatch(showErrorToast({
			description: 'Не удалось получить список рефералов пользователя'
		}));

		return Promise.reject(error);
		
	} finally {

		dispatch(setReferralLoading(false));

	}
};

export default referralsSlice.reducer;