import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
};

const coursesSlice = createSlice({
	name: 'courses',
	initialState,
	reducers: {
		setCoursesList: (state, action) => {
			state.list = action.payload;
		},
		addCoursesItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeCoursesItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		updateCourseItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			if (index !== -1) {
				state.list.splice(index, 1, action.payload);
			}
		}
	},
});

export const { setCoursesList, addCoursesItem, removeCoursesItem, updateCourseItem } = coursesSlice.actions;

export const fetchCourseList = () => async (dispatch) => {
	try {

		const res = await api.coursesList();
		dispatch(setCoursesList(res.list));

	} catch (error) {

		return Promise.reject(error);
		
	}
};

export const getCourseById = (id) => ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {

		const state = getState();

		const list = state.courses.list;

		let item = list.find(item => item.id === id);

		if (item) {

			resolve(item);

		} else {

			api.courseById(id)
				.then(res => {
					dispatch(addCoursesItem(res));
					resolve(res);
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Курс ${id} не найден`
					}));

					reject();

				});
		}

	});

};

export const reloadCourse = (courseId) => async (dispatch) => {

	try {

		const course = await api.courseById(courseId);

		if (course) {
			dispatch(updateCourseItem(course));
		}

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось обновить курс'
		}));

	}

};

export const createCourseModule = ({courseId, module}) => async (dispatch) => {

	try {

		await api.createCourseModule({
			courseId,
			module
		});

		dispatch(reloadCourse(courseId));

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось создать модуль'
		}));

	}

};

export const editCourseModule = ({ courseId, module }) => async (dispatch) => {

	try {

		await api.editCourseModule({
			courseId,
			module
		});

		dispatch(reloadCourse(courseId));

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось редактировать модуль'
		}));

	}

};

export const removeCourseModule = ({ courseId, moduleId }) => async (dispatch) => {

	try {

		await api.removeCourseModule({
			courseId,
			moduleId
		});

		dispatch(reloadCourse(courseId));

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось создать модуль'
		}));

	}

};

export const removeModuleAttachment = ({ courseId, moduleId, attachmentId }) => async (dispatch) => {

	try {

		await api.removeAttachmentModule({
			courseId,
			moduleId,
			attachmentId
		});

		dispatch(reloadCourse(courseId));

	} catch (e) {

		console.error(e);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось удалить файл модуля'
		}));

	}

};

export default coursesSlice.reducer;
