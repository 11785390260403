import $axios from './api';

export const createSession = async ({ fileName, size }) => {
	const { data } = await $axios.post('/v1/author/upload/session/new', { fileName, size });
	return data;
};

export const uploadSessionFile = async ({ sessionId, fileChunk }) => {
	const { data } = await $axios.post(`/v1/author/upload/session/${sessionId}/part`, fileChunk);
	return data;
};

export const closeSession = async (sessionId) => {
	const { data } = await $axios.post(`/v1/author/upload/session/${sessionId}/close`);
	return data;
};

export const getSessionById = async (sessionId) => {
	const { data } = await $axios.get(`/v1/author/upload/session/${sessionId}`);
	return data;
};

export const uploadImage = async (blobFile) => {
	const { data } = await $axios.post('/v1/author/upload/image', blobFile);
	return data;
};

export const uploadRegisterImage = async (blobFile) => {
	const { data } = await $axios.post('/v1/author/register/avatar', blobFile);
	return data;
};

export const uploadAttachmentModule = async ({ courseId, moduleId, file }) => {
	const { data } = await $axios.post(`/v1/author/course/${ courseId }/module/${moduleId}/attach/upload`, file);
	return data;
};