import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	authUser: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthUser: (state, action) => {
			state.authUser = action.payload;
		},
	},
});

export const { setAuthUser } = authSlice.actions;

export const getAuthUser = () => async (dispatch) => {

	try {

		const user = await api.getUser();

		if (user) {

			dispatch(setAuthUser(user));
		}

	} catch (error) {

		return Promise.reject(error);
		
	}
};

export const updateUserData = (userData) => async (dispatch) => {

	try {

		const userId = await api.updateUserData(userData);

		await dispatch(getAuthUser(userId));

	} catch(error) {

		dispatch(showErrorToast({
			description: 'Не удалось обновить данные пользователя'
		}));

	}

};

export default authSlice.reducer;
