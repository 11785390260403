import $axios from './api';

export const getStreamById = async (streamId) => {

	const { data } = await $axios.get(`/v1/author/stream/${streamId}`);
	return data;

};

export const getStreamLinkById = async (streamId) => {
	
	const { data } = await $axios.get(`/v1/author/stream/${streamId}/link`);
	return data;

};

export const getStreamList = async () => {
	
	const { data } = await $axios.post('/v1/author/stream/list');
	return data;
	
};
