import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	modalName: '',
	props: null,
	settings: null
};

const modalSlice = createSlice({
	initialState,
	name: 'modal',
	reducers: {
		openModal: (state, action) => {
			state.modalName = action.payload.modalName;
			state.props = action.payload.props;
			
			if (action.payload.settings) {
				state.settings = action.payload.settings;
			}
		},
		closeModal: (state) => {
			state.modalName = '';
			state.props = null;
			state.settings = null;

		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;