import $axios from './api';

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/author/webinar/${ id }`);
	return data;

};

export const create = async ( payload ) => {

	const { data } = await $axios.post('/v1/author/webinar/create', payload );
	return data;

};

export const list = async ( payload = {} ) => {

	const { data } = await $axios.post( '/v1/author/webinar/list', payload );
	return data;

};

export const edit = async ( id, payload ) => {

	const { data } = await $axios.post( `/v1/author/webinar/${ id }`, payload );
	return data;

};

export const cancel = async (id) => {

	const { data } = await $axios.post( `/v1/author/webinar/cancel/${ id }` );
	return data;

};

export const editPublication = async ( publicationId, payload) => {

	const { data } = await $axios.post( `/v1/author/webinar/publication/${ publicationId }`, payload );
	return data;

};